<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>数据统计</template>
      <template v-slot:secondMenu>分享统计</template>
    </breadcrumb-nav>
    <el-card>
      <el-tabs  v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane label="分享点击" name="enter">
          <stat-enter v-if="tabRefresh.enter"></stat-enter>
        </el-tab-pane>
        <el-tab-pane label="邀请注册" name="regist">
          <stat-regist v-if="tabRefresh.regist"></stat-regist>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import StatEnter from './childComponents/StatEnter'
import StatRegist from './childComponents/StatRegist'
export default {
  name: 'StatShare',
  components: {
    BreadcrumbNav,
    StatRegist,
    StatEnter
  },
  data () {
    return {
      activeName: 'enter',
      tabRefresh: {
        enter: true,
        regist: false
      }
    }
  },
  methods: {
    handleTabClick (tab) {
      this.activeName = tab.name
      this.switchTab(this.activeName)
    },
    switchTab (tab) {
      for (const key in this.tabRefresh) {
        if (key === tab) {
          this.tabRefresh[key] = true
        } else {
          this.tabRefresh[key] = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
