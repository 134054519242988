<template>
 <div>
   <div class="overview-layout">
     <el-row :gutter="24">
       <el-col :span="24">
         <div class="out-border">
           <div class="layout-title">分享点击总览</div>
           <div class="layout-content" style="padding: 20px">
             <el-row>
               <el-col :span="8" class="color-danger overview-item-value">{{statShareCount.today}}</el-col>
               <el-col :span="8" class="color-danger overview-item-value">{{statShareCount.yesterday}}</el-col>
               <el-col :span="8" class="color-danger overview-item-value">{{statShareCount.total}}</el-col>
             </el-row>
             <el-row class="font-medium">
               <el-col :span="8" class="overview-item-title">今日点击数</el-col>
               <el-col :span="8" class="overview-item-title">昨日点击数</el-col>
               <el-col :span="8" class="overview-item-title">总点击数</el-col>
             </el-row>
           </div>
         </div>
       </el-col>
     </el-row>
   </div>
   <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline" style="margin-top: 10px;margin-left: 5px;">
     <el-form-item label="日期范围" prop="date">
       <el-date-picker
         v-model="listQuery.date"
         type="daterange"
         align="right"
         prop="date"
         value-format="timestamp"
         range-separator="至"
         start-placeholder="开始日期"
         end-placeholder="结束日期"
         :picker-options="pickerOptions"
       >
       </el-date-picker>
     </el-form-item>
     <el-form-item>
       <el-button type="primary" @click="onSubmit">查询</el-button>
       <el-button @click="resetForm('listQuery')">重置</el-button>
     </el-form-item>
   </el-form>
   <el-table class="custom-user-table" :data="list" border stripe>
     <el-table-column prop="imageUrl" label="头像" width="80px">
       <template slot-scope="{ row }">
         <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px; border-radius: 5px;" />
       </template>
     </el-table-column>
     <el-table-column prop="showName" label="昵称" width="100px"></el-table-column>
     <el-table-column prop="realRegistTime" label="注册日期" width="120px">
       <template slot-scope="scope">{{ scope.row.realRegistTime | formatDate }}</template>
     </el-table-column>
     <el-table-column prop="registAddr" label="注册地"></el-table-column>
     <el-table-column prop="lastLoginTime" label="最后活跃时间">
       <template slot-scope="scope">{{ scope.row.lastActiveTime | formatDateTime }}</template>
     </el-table-column>
     <el-table-column prop="brand" label="设备品牌"></el-table-column>
     <el-table-column prop="total" label="点击人数"></el-table-column>
   </el-table>
   <el-pagination
     @size-change="handleSizeChange"
     @current-change="handleCurrentChange"
     :current-page="listQuery.page"
     :page-sizes="[5, 10, 20]"
     :page-size="listQuery.size"
     layout="total, sizes, prev, pager, next, jumper"
     :total="total">
   </el-pagination>
 </div>
</template>

<script>

import { getStatUserShareEnter, getStatUserShareEnterTotal } from '../../../network/statistics'

export default {
  name: 'StatEnter',
  data () {
    return {
      list: [],
      total: 0,
      listQuery: {
        date: '',
        page: 1,
        size: 10
      },
      statShareCount: {
        today: 0,
        yesterday: 0,
        total: 0
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '今日',
            onClick (picker) {
              const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
              const end = new Date(new Date().setHours(23, 59, 59, 999)).getTime()
              picker.$emit('pick', [start, end])
            }
          },
          {
            text: '昨日',
            onClick (picker) {
              const start = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
              const end = new Date(new Date().setHours(23, 59, 59, 999)).getTime()
              const dayTime = 24 * 3600 * 1000
              picker.$emit('pick', [start - dayTime, end - dayTime])
            }
          },
          {
            text: '本月',
            onClick (picker) {
              const date = new Date()
              const year = date.getFullYear()
              const month = date.getMonth()
              const start = new Date(year, month, 1).getTime()
              const end = new Date(year, month + 1, 1).getTime() - 1
              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  filters: {
    formatDate (value) {
      if (value === 0) {
        return ''
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    formatDateTime (value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }
  },
  mounted () {
    this.getTotal()
    this.getList()
  },
  methods: {
    onSubmit () {
      this.getList()
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    getTotal () {
      getStatUserShareEnterTotal({}).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.statShareCount = result.data.shareTotal
      })
    },
    getList () {
      console.log('this.query-------:', this.listQuery)
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size
      }
      if (this.listQuery.date && this.listQuery.date.length > 1) {
        query.startTime = this.listQuery.date[0]
        query.endTime = this.listQuery.date[1]
      }
      getStatUserShareEnter(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取数据失败', 'error')
        }
        this.list = result.data.list
        this.total = result.data.total
        for (const item of this.list) {
          if (item.showAvatar.includes('http')) {
            item.imageUrl = item.showAvatar
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.showAvatar
          }
          if (item.province === '0') {
            item.province = ''
          }
          if (item.city === '0') {
            item.city = ''
          }
          if (item.loginProvince === '0') {
            item.loginProvince = ''
          }
          if (item.loginCity === '0') {
            item.loginCity = ''
          }
          if (item.province !== '' && item.city !== '') {
            item.registAddr = item.province + '-' + item.city
          } else if (item.province !== '') {
            item.registAddr = item.province
          }
        }
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    }
  }
}
</script>

<style scoped>
.app-container {
}

.address-layout {
}

.total-layout {
  margin-top: 20px;
}

.total-frame {
  border: 1px solid #DCDFE6;
  padding: 20px;
  height: 100px;
}

.total-icon {
  color: #409EFF;
  width: 60px;
  height: 60px;
}

.total-title {
  position: relative;
  font-size: 16px;
  color: #909399;
  left: 70px;
  top: -50px;
}

.total-value {
  position: relative;
  font-size: 18px;
  color: #606266;
  left: 70px;
  top: -40px;
}

.un-handle-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}

.layout-title {
  color: #606266;
  padding: 15px 20px;
  background: #F2F6FC;
  font-weight: bold;
}

.layout-content {
  background: #ffffff;
}

.un-handle-content {
  padding: 20px 40px;
}

.un-handle-item {
  border-bottom: 1px solid #EBEEF5;
  padding: 10px;
}

.overview-layout {
}

.overview-item-value {
  font-size: 24px;
  text-align: center;
}

.overview-item-title {
  margin-top: 10px;
  text-align: center;
}

.out-border {
  border: 1px solid #DCDFE6;
}

.color-danger{
  color: #F56C6C;
}

.statistics-layout {
  margin-top: 20px;
  border: 1px solid #DCDFE6;
}
.mine-layout {
  position: absolute;
  right: 140px;
  top: 107px;
  width: 250px;
  height: 235px;
}
.address-content{
  padding: 20px;
  font-size: 18px
}
</style>
